var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-iterator',{ref:"iterator",staticClass:"canteen-table-component",attrs:{"api":_vm.api,"filters":_vm.filters,"fixed-filters":_vm.fixedFilters},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"filters",fn:function(ref){
var busy = ref.busy;
return [_c('table-filters',{attrs:{"fields":_vm.headers,"busy":busy,"list-filters":_vm.listFilters},on:{"input":_vm.updateFilters},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})]}},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{attrs:{"items":items,"headers":_vm.headers,"filters":_vm.filters,"expandable":true,"expand-front":true,"row-action":""},on:{"update:filters":function($event){_vm.filters=$event},"item-expanded":_vm.onExpand,"onRowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.identifier",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.identifier))]),_c('small',{staticClass:"g-73--text"},[_vm._v(_vm._s(_vm._f("transformDate")(item.cutoffTime)))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"canteen-name d-block text-truncate"},[_vm._v(" "+_vm._s(item.companyOrDropoffName)+" ")]),_c('small',{staticClass:"g-73--text canteen-name d-block text-truncate"},[_vm._v(" "+_vm._s(item.canteenName)+" ")])]}},{key:"item.dropoff",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm._f("transformDate")(item.deliveryTime))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("toDate")(item.deliveryTime,'YYYY-MM-DD HH:mm')))])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(_vm.expanded[item.canteenIri])?[_c('td',{staticClass:"expanded-actions",attrs:{"colspan":"2"}},[_c('span',{staticClass:"expanded-header"},[_vm._v(_vm._s(_vm.$t('labels.delivery')))]),_vm._l((_vm.getExpandedItem(item)
                                .restaurants),function(related){return _c('div',{key:related['@id'] + '_delivery',staticClass:"text-line"},[(related.preJob)?_c('expanded-delivery-info',{attrs:{"job":related.preJob}}):_vm._e()],1)})],2),_c('td',[_c('span',{staticClass:"expanded-header"},[_vm._v(_vm._s(_vm.$t('labels.restaurant')))]),_vm._l((_vm.getExpandedItem(item)
                                .restaurants),function(related){return _c('div',{key:related['@id'] + '_restaurant',staticClass:"text-line text-truncate restaurant-info"},[_vm._v(" "+_vm._s(related.restaurantName)+" "),_c('div',{staticClass:"noti-info"},_vm._l((related.persistent),function(data,key){return _c('v-tooltip',{key:key,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-icon',_vm._g({class:{
                                                'green--text': data,
                                            },attrs:{"left":""}},on),[_vm._v(" keyboard_arrow_right ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(("canteen_noti_info." + key)))+": "+_vm._s(data || _vm.$t('labels.no'))+" ")])])}),1)])})],2),_c('td',[_c('span',{staticClass:"expanded-header"},[_vm._v(_vm._s(_vm.$t('labels.address')))]),_vm._l((_vm.getExpandedItem(item)
                                .restaurants),function(related){return _c('div',{key:related['@id'] + '_address',staticClass:"text-line text-truncate canteen-address-wlimit",attrs:{"title":related.addressName}},[_vm._v(" "+_vm._s(related.addressName)+" ")])})],2),_c('td',{staticClass:"expanded-actions text-right"},[_c('span',{staticClass:"expanded-header"}),_vm._l((_vm.getExpandedItem(item)
                                .restaurants),function(related){return _c('div',{key:related['@id'] + '_pedalme',staticClass:"py-2"},[(related.preJob)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"blue-grey"},on:{"click":function($event){;(_vm.pedalmeJob = related.preJob),
                                                (_vm.pedalmeItem = item),
                                                (_vm.pedalmeDialog = true)}}},on),[_c('v-icon',[_vm._v("pedal_bike")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.pedalme_tooltip')))])]):(item.preCreateEnabled)?_c('span',{staticClass:"d-inline-block py-2"},[_vm._v(_vm._s(_vm.$t('texts.pre_create_to_enable_pedalme')))]):_vm._e()],1)})],2)]:_c('td',{staticClass:"text-center",attrs:{"colspan":headers.length}},[_c('v-progress-circular',{attrs:{"size":"50","width":"3","color":"amber","indeterminate":""}})],1)]}},{key:"rowActions",fn:function(ref){
                                                var item = ref.item;
return [(_vm.$date(item.cutoffTime) > _vm.$date())?_c('div',{staticStyle:{"display":"inline-block"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"blue-grey","disabled":item.preCreateEnabled === false},on:{"click":function($event){;(_vm.dialog = true), (_vm.selectedItem = item)}}},on),[_c('v-icon',[_vm._v("two_wheeler")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('actions.send_pre_delivery')))])])],1):_vm._e()]}}],null,true)}),_c('confirm-pre-delivery-modal',{attrs:{"title":_vm.$t('texts.request_courier'),"text":_vm.$t('texts.do_you_want_request_courier', {
                    item: ("" + _vm.selectedItemIdentifier),
                }),"order-id":_vm.selectedItemIdentifier,"approve-color":"green","approve-text":_vm.$t('actions.confirm')},on:{"onApprove":_vm.sendPreDelivery,"onCancel":function($event){;(_vm.selectedItem = null), (_vm.dialog = false)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('pedalme-delivery-modal',{attrs:{"delivery-job":_vm.pedalmeJob},on:{"onApprove":_vm.sendPedalme},model:{value:(_vm.pedalmeDialog),callback:function ($$v) {_vm.pedalmeDialog=$$v},expression:"pedalmeDialog"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }