<template>
    <div>
        <page-title>{{ $t('menu.canteens') }}</page-title>
        <canteen-table ref="table"></canteen-table>
    </div>
</template>

<script>
import PageTitle from '@/components/layout/components/pageTitle'
import routeType from '@/router/routeType'
import CanteenTable from '@/components/pages/canteen/CanteenTable'

export default {
    components: {
        PageTitle,
        CanteenTable,
    },
    data() {
        return {
            routeType,
        }
    },
}
</script>
