export default {
    computed: {
        headers() {
            const fields = [
                {
                    value: 'identifier',
                    text: this.$t('labels.identifier'),
                    type: 'string',
                    sortable: false,
                },
                {
                    value: 'name',
                    text: this.$t('labels.company'),
                    type: 'string',
                    sortable: false,
                },
                {
                    value: 'dropoff',
                    text: this.$t('labels.dropoff'),
                    type: 'string',
                    sortable: false,
                    class: 'canteen-dropoff-header',
                },
            ]

            return fields
        },
    },
}
