<template>
    <data-iterator
        ref="iterator"
        :api="api"
        :filters.sync="filters"
        :fixed-filters="fixedFilters"
        class="canteen-table-component"
    >
        <template v-slot:actions>
            <slot name="actions"></slot>
        </template>

        <template v-slot:filters="{ busy }">
            <table-filters
                v-model="filters"
                :fields="headers"
                :busy="busy"
                :list-filters="listFilters"
                @input="updateFilters"
            ></table-filters>
        </template>

        <template slot-scope="{ items }">
            <d-table
                :items="items"
                :headers="headers"
                :filters.sync="filters"
                :expandable="true"
                :expand-front="true"
                row-action
                @item-expanded="onExpand"
                @onRowSelected="onRowSelected"
            >
                <!-- identifier column -->
                <template v-slot:[`item.identifier`]="{ item }">
                    <div>{{ item.identifier }}</div>
                    <small class="g-73--text">{{
                        item.cutoffTime | transformDate
                    }}</small>
                </template>

                <!-- name column -->
                <template v-slot:[`item.name`]="{ item }">
                    <div class="canteen-name d-block text-truncate">
                        {{ item.companyOrDropoffName }}
                    </div>
                    <small
                        class="g-73--text canteen-name d-block text-truncate"
                    >
                        {{ item.canteenName }}
                    </small>
                </template>

                <!-- dropoff column -->
                <template v-slot:[`item.dropoff`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <span v-on="on">
                                {{ item.deliveryTime | transformDate }}
                            </span>
                        </template>
                        <span>{{
                            item.deliveryTime | toDate('YYYY-MM-DD HH:mm')
                        }}</span>
                    </v-tooltip>
                </template>

                <!-- expanded row -->
                <template v-slot:expanded-item="{ headers, item }">
                    <template v-if="expanded[item.canteenIri]">
                        <!-- expanded delivery column -->
                        <td colspan="2" class="expanded-actions">
                            <span class="expanded-header">{{
                                $t('labels.delivery')
                            }}</span>
                            <div
                                v-for="related in getExpandedItem(item)
                                    .restaurants"
                                :key="related['@id'] + '_delivery'"
                                class="text-line"
                            >
                                <expanded-delivery-info
                                    v-if="related.preJob"
                                    :job="related.preJob"
                                ></expanded-delivery-info>
                            </div>
                        </td>
                        <!-- expanded restaurant column -->
                        <td>
                            <span class="expanded-header">{{
                                $t('labels.restaurant')
                            }}</span>
                            <div
                                v-for="related in getExpandedItem(item)
                                    .restaurants"
                                :key="related['@id'] + '_restaurant'"
                                class="text-line text-truncate restaurant-info"
                            >
                                {{ related.restaurantName }}
                                <div class="noti-info">
                                    <v-tooltip
                                        v-for="(data,
                                        key) in related.persistent"
                                        :key="key"
                                        bottom
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                :class="{
                                                    'green--text': data,
                                                }"
                                                left
                                                v-on="on"
                                            >
                                                keyboard_arrow_right
                                            </v-icon>
                                        </template>
                                        <span>
                                            {{
                                                $t(`canteen_noti_info.${key}`)
                                            }}:
                                            {{ data || $t('labels.no') }}
                                        </span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </td>
                        <!-- expanded address column -->
                        <td>
                            <span class="expanded-header">{{
                                $t('labels.address')
                            }}</span>
                            <div
                                v-for="related in getExpandedItem(item)
                                    .restaurants"
                                :key="related['@id'] + '_address'"
                                class="text-line text-truncate canteen-address-wlimit"
                                :title="related.addressName"
                            >
                                {{ related.addressName }}
                            </div>
                        </td>
                        <!-- expanded actions column -->
                        <td class="expanded-actions text-right">
                            <span class="expanded-header"></span>
                            <div
                                v-for="related in getExpandedItem(item)
                                    .restaurants"
                                :key="related['@id'] + '_pedalme'"
                                class="py-2"
                            >
                                <v-tooltip v-if="related.preJob" bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            text
                                            icon
                                            color="blue-grey"
                                            v-on="on"
                                            @click="
                                                ;(pedalmeJob = related.preJob),
                                                    (pedalmeItem = item),
                                                    (pedalmeDialog = true)
                                            "
                                        >
                                            <v-icon>pedal_bike</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{
                                        $t('labels.pedalme_tooltip')
                                    }}</span>
                                </v-tooltip>
                                <span
                                    v-else-if="item.preCreateEnabled"
                                    class="d-inline-block py-2"
                                    >{{
                                        $t('texts.pre_create_to_enable_pedalme')
                                    }}</span
                                >
                            </div>
                        </td>
                    </template>
                    <!-- expanded loading placeholder -->
                    <td v-else :colspan="headers.length" class="text-center">
                        <v-progress-circular
                            size="50"
                            width="3"
                            color="amber"
                            indeterminate
                        ></v-progress-circular>
                    </td>
                </template>

                <!-- row actions -->
                <template v-slot:rowActions="{ item }">
                    <div
                        v-if="$date(item.cutoffTime) > $date()"
                        style="display: inline-block"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    text
                                    icon
                                    color="blue-grey"
                                    :disabled="item.preCreateEnabled === false"
                                    v-on="on"
                                    @click="
                                        ;(dialog = true), (selectedItem = item)
                                    "
                                >
                                    <v-icon>two_wheeler</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('actions.send_pre_delivery') }}</span>
                        </v-tooltip>
                    </div>
                </template>
            </d-table>
            <confirm-pre-delivery-modal
                v-model="dialog"
                :title="$t('texts.request_courier')"
                :text="
                    $t('texts.do_you_want_request_courier', {
                        item: `${selectedItemIdentifier}`,
                    })
                "
                :order-id="selectedItemIdentifier"
                approve-color="green"
                :approve-text="$t('actions.confirm')"
                @onApprove="sendPreDelivery"
                @onCancel=";(selectedItem = null), (dialog = false)"
            ></confirm-pre-delivery-modal>
            <pedalme-delivery-modal
                v-model="pedalmeDialog"
                :delivery-job="pedalmeJob"
                @onApprove="sendPedalme"
            ></pedalme-delivery-modal>
        </template>
    </data-iterator>
</template>

<script>
import { mapGetters } from 'vuex'
import RestApiType from '@/api/RestApiType'
import DeliveryApi from '@/api/RestApi/DeliveriesApiClient'
import SAT from '@/store/type/selectapiType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import Notification from '@/services/Notification/Notification'
import Headers from '@/components/pages/canteen/mixins/Headers'
import ListFilters from '@/components/pages/canteen/mixins/ListFilters'
import ConfirmPreDeliveryModal from '@/components/confirm/ConfirmPreDeliveryModal'
import ExpandedDeliveryInfo from './ExpandedDeliveryInfo'
import expandedItemTransformer from '@/services/Canteens/CanteenExpandedItemTransformer'
import PedalmeDeliveryModal from './PedalmeDeliveryModal'

const deliveryApiMethod = (item) =>
    item.type === 'cloudcanteen'
        ? 'preCreateDeliveryCC'
        : 'preCreateDeliveryResidentialCC'

export default {
    components: {
        ConfirmPreDeliveryModal,
        ExpandedDeliveryInfo,
        PedalmeDeliveryModal,
    },
    mixins: [DataIteratorMixin, DataTableMixin, Headers, ListFilters],
    props: {
        fixedFilters: {
            type: Object,
            default: () => {
                return {
                    order: {
                        deliveryTime: 'desc',
                    },
                }
            },
        },
    },
    data() {
        return {
            api: RestApiType.CANTEENS,
            dialog: false,
            selectedItem: null,
            pedalmeDialog: false,
            pedalmeJob: null,
            pedalmeItem: null,
            addresses: {},
            expandedItems: {},
            expanded: {},
            filters: {},
        }
    },
    computed: {
        ...mapGetters({
            compAddresses: `${SAT.getModuleName(
                SAT.clientTypes.COMPANY_ADDRESS
            )}/${SAT.getters.VALUES}`,
        }),
        companyAddresses() {
            const companyAddresses = this.compAddresses({})
            const addresses = {}
            for (let el of companyAddresses) {
                addresses[el.key] = el.label
            }

            return addresses
        },
        selectedItemIdentifier() {
            return this.selectedItem?.identifier || null
        },
    },
    mounted() {
        this.$store.dispatch(
            SAT.getActionName(SAT.clientTypes.COMPANY_ADDRESS, SAT.actions.LOAD)
        )
    },
    methods: {
        getExpandedItem(item, forceRefresh = false) {
            const id = item.canteenIri

            if (!this.expanded[id]) this.$set(this.expanded, id, false)

            if (forceRefresh || !this.expandedItems[id]) {
                this.$set(this.expandedItems, id, { restaurants: [] })

                expandedItemTransformer(
                    item,
                    this.companyAddresses,
                    (result) => {
                        this.$set(this.expandedItems, id, result)
                        this.$set(this.expanded, id, true)
                    }
                )
            }

            return this.expandedItems[id]
        },
        sendPreDelivery(deliveryMethod) {
            const method = deliveryApiMethod(this.selectedItem)
            DeliveryApi[method](this.selectedItem.id, deliveryMethod)
                .then((data) => {
                    if (data.success === false) {
                        Notification.error(
                            this.$t('notifications.pre_delivery_error.title', {
                                id: this.selectedItem.identifier,
                            }),
                            data.message
                        )
                    } else {
                        Notification.success(
                            this.$t(
                                'notifications.pre_delivery_success.title',
                                {
                                    id: this.selectedItem.identifier,
                                }
                            ),
                            data.message
                        )
                        this.getExpandedItem(this.selectedItem, true)
                    }
                })
                .catch(() => {
                    this.$emit('failed', this.selectedItem.id)
                    Notification.error(
                        this.$t('notifications.pre_delivery_failed.title', {
                            id: this.selectedItem.identifier,
                        })
                    )
                })
                .finally(() => {
                    this.$refs.iterator.updateItems(true, true)
                })
        },
        sendPedalme(pickupTime, selection) {
            DeliveryApi.sendToPedalme(pickupTime, selection)
                .then(() => {
                    this.getExpandedItem(this.pedalmeItem, true)
                    Notification.success(
                        this.$t('notifications.pedalme.success')
                    )
                })
                .catch((err) => {
                    let message = err.response?.data?.errors?.join(';')
                    Notification.error(
                        this.$t('notifications.pedalme.fail', {
                            message,
                        })
                    )
                })
                .finally(() => {
                    this.pedalmeDialog = false
                })
        },
        onExpand(event) {
            const { item, value } = event
            const id = item.canteenIri

            if (!this.expanded[id]) this.$set(this.expanded, id, false)

            if (value) this.getExpandedItem(item)
        },
    },
}
</script>

<style lang="scss">
$addrw: 190px;
$actw: 220px;
.canteen-table-component .data-table {
    .canteen-dropoff-header {
        width: $addrw;
        max-width: $addrw;
    }
    .row-action {
        opacity: 1;
    }
    // .row-actions-header {
    //     min-width: $actw;
    // }
}
.canteen-address-wlimit {
    max-width: $addrw;
}
.v-data-table__expanded__row,
.expanded-header {
    background-color: #ddd;
}
.expanded-header {
    display: block;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    padding: 4px 16px;
    margin: 0 -16px;
    height: 24px;
    border-bottom: 1px solid #666;
}
.text-line {
    line-height: 24px;
    padding: 14px 0;
}
.v-data-table__expanded__content td > div {
    margin: 0 -16px;
    padding-left: 16px;
    padding-right: 16px;
}
.v-data-table__expanded__content td > div:not(:last-of-type) {
    border-bottom: 1px solid #aaa;
}
.expanded-actions > div {
    height: 53px;
    &:last-of-type {
        height: 52px;
    }
}
.restaurant-info {
    position: relative;

    .noti-info {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .v-icon {
            margin-right: -6px;
        }
    }
}
</style>
