import map from 'lodash/map'
import CCApi from '@/api/RestApi/CompanyCloudcanteens'
import RCApi from '@/api/RestApi/ResidentialCanteensApiClient'
import DeliveryApi from '@/api/RestApi/DeliveriesApiClient'
import { getId } from '@/services/IdFromIri'

const getItemApi = (item) => (item.type === 'cloudcanteen' ? CCApi : RCApi)
const getDeliveryType = (item) =>
    item.type === 'cloudcanteen' ? 'cloud_canteen' : 'residential_canteen'

const itemPromises = {}

export default function (item, companyAddrObj, callback = Function()) {
    if (!itemPromises[item.canteenIri])
        itemPromises[item.canteenIri] = getItemApi(item).get(item.id)

    const itemPromise = itemPromises[item.canteenIri]
    const deliveryPromise = DeliveryApi.list({
        'order[deliveryJob.orderCreatedAt]': 'desc',
        'preCreatedFor[type]': getDeliveryType(item),
        'preCreatedFor[id]': item.id,
    })

    Promise.all([itemPromise, deliveryPromise]).then(
        ([canteen, deliveryProviderJobs]) => {
            canteen.restaurants = []
            const deliveryJobs =
                map(deliveryProviderJobs['hydra:member'], 'deliveryJob') || []

            canteen.relatedRestaurants.forEach((related) => {
                const restaurantId = getId(related.restaurant)

                if (canteen.addresses) {
                    /* CC */
                    canteen.addresses.forEach((addressIri) => {
                        const addressId = getId(addressIri)

                        const preJob = deliveryJobs.find(
                            (dJ) =>
                                dJ?.preCreatedFor?.restaurant_id ===
                                    restaurantId &&
                                dJ?.preCreatedFor?.address_id === addressId
                        )

                        const persistent = {}
                        for (const key of [
                            'added_to_canteen',
                            'first_reminder',
                            'second_reminder',
                        ]) {
                            persistent[key] =
                                related.persistentData?.[key]?.date || null
                        }

                        canteen.restaurants.push({
                            '@id': related['@id'] + addressIri,
                            pickupTime: related.pickupTime,
                            restaurantIri: related.restaurant,
                            restaurantName: related.restaurantName,
                            preJob,
                            addressIri,
                            addressName: companyAddrObj[addressIri],
                            persistent,
                        })
                    })
                } else {
                    /* RC */
                    const preJob = deliveryJobs.find(
                        (dJ) =>
                            dJ?.preCreatedFor?.restaurant_id === restaurantId
                    )

                    canteen.restaurants.push({
                        '@id': related['@id'],
                        pickupTime: related.pickupTime,
                        restaurantIri: related.restaurant,
                        restaurantName: related.restaurantName,
                        preJob,
                        addressIri: canteen.address['@id'],
                        addressName: canteen.address.name,
                        persistent: {
                            added_to_canteen: null,
                            first_reminder: null,
                            second_reminder: null,
                        },
                    })
                }
            })

            callback(canteen)
        }
    )
}
