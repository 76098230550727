<template>
    <div>
        {{ $t(`active_delivery_method.${job.primaryDeliveryMethod}`) }}
        <v-icon :color="color">location_on</v-icon>
        {{ status }}
    </div>
</template>

<script>
import {
    getJobStatusTranslation,
    getJobColor,
} from '@/services/Deliveries/JobStatus'

export default {
    props: {
        job: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        status() {
            return this.$t(getJobStatusTranslation(this.job))
        },
        color() {
            return getJobColor(this.job)
        },
    },
}
</script>
