export function getJobStatusTranslation(job) {
    if (job.status === 'in_progress') {
        if (job.courierStatus) return `courier_status.${job.courierStatus}`
        else return 'courier_status.searching'
    } else return `delivery_job_status.${job.status}`
}

export function getJobColor(job) {
    let status =
        job.status === 'in_progress'
            ? job.courierStatus || 'searching'
            : job.status
    switch (status) {
        case 'initialized':
        case 'queued':
            return 'g-cc'
        case 'in_progress':
        case 'pending':
        case 'picking':
        case 'almost_picking':
        case 'waiting_at_pickup':
        case 'delivering':
        case 'almost_delivering':
        case 'waiting_at_dropoff':
            return 'accent'
        case 'finished':
        case 'delivered':
        case 'closed':
            return 'success'
        case 'cancelled':
        case 'error':
            return 'error'
        case 'merged':
        case 'grouping':
        case 'searching':
            return 'info'
        default:
            return 'yellow'
    }
}
